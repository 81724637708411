const initialFilters = {
  number: null,
  address: null,
  sources: {
    options: [
      { id: 1, name: 'Невывоз' },
      { id: 2, name: 'Позиция' },
      { id: 3, name: 'Заявка' },
      { id: 4, name: 'Доп вывоз' },
    ],
    value: null,
  },
}

const defaultState = () => ({
  isLoadingTableData: false,
  isUpdateTableData: false,
  dates: {
    start: null,
    end: null,
  },
  activeNotification: null,
  filters: initialFilters,
})

export const notifications_page_notifications_frame = {
  namespaced: true,
  state: defaultState(),
  getters: {
    dates: state => state.dates,
    isLoadingTableData: state => state.isLoadingTableData,
    isUpdateTableData: state => state.isUpdateTableData,
    activeNotification: state => state.activeNotification,
    filters: state => state.filters,
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    changeDates({ commit }, payload) {
      commit('changeDates', payload);
    },
    setLoadingTableData({ commit }, payload) {
      commit('setLoadingTableData', payload);
    },
    updateTableData({ commit }, payload) {
      commit('updateTableData', payload);
    },
    setActiveNotification({ commit }, payload) {
      commit('setActiveNotification', payload);
    },
    changeFilter({ commit }, payload) {
      commit('changeFilter', payload);
    },
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    changeDates(state, payload) {
      state.dates = payload;
    },
    setLoadingTableData(state, payload) {
      state.isLoadingTableData = payload;
    },
    updateTableData(state, payload) {
      state.isUpdateTableData = payload;
    },
    setActiveNotification(state, payload) {
      state.activeNotification = payload;
    },
    changeFilter(state, payload) {
      state.filters = payload;
    },
  },
};